<template>
  <router-link :to="'/' + (media.type) + '/' + media.id">
      <div class="vod" :class="{list: list, animated: loader, display: display}" v-if="!timedOut">
          <transition name="fade">
              <img class="splash" v-if="!list" :src="media.poster_path" v-on:load="onLoaded" v-show="loaded" />
          </transition>

          <div class="poster animated" v-if="!loaded" />

          <transition name="fade">
              <img class="poster" :src="media.poster_path" v-on:load="onLoaded" v-show="loaded" v-if="!loader"  />
          </transition>

          <transition name="fade">
              <span v-show="loaded">
                {{media.title}}
              </span>
          </transition>

      </div>
  </router-link>
</template>

<script>

export default {
  name: 'vodcomponent',

  props: {
    media: Object,

    display: {
        type: Boolean,
        default: function(){ return false }
    },

    list: {
        type: Boolean,
        default: function(){ return false }
    }

  },

  data: function(){
    return {
        loaded: false,
        timedOut: false,
        loader: false,
    }
  },

  watch:{
      media: function(){
          this.loader = this.media.title == 'Loading...'
      }
  },

  methods: {
    onLoaded: function() {
      this.loaded = true;
    },
  },

  mounted: function(){ 
      this.loader = this.media.title == 'Loading...'
        setTimeout(() => {
            if(!this.loaded){ this.timedOut = true }
        }, 10000)
  }
}
</script>

<style scoped lang="scss">
.vod{
    width: 100vw;
    height: calc(100vh - 220px);
    span{
        display: none;
    }
    &.list{
        position: relative;
        width: 150px;
        height: 225px;
        margin-right: 10px;
        border-radius: 4px;
        overflow: hidden;
        box-sizing: border-box;
        &.display{
            span{
                display: none;
            }
        }
        span{
            font-size: 8pt;
            max-height: 40px;
            text-overflow: ellipsis;
            display: block;
            padding: 10px;
            align-self: center;
            font-weight: bold;
        }
        img{
            width: 100%;
            min-height: 100%;
            object-fit: cover;
            &.splash{ 
                display: none;
            }
        }

    }
    &:not(.list){
        img.splash{
            transform: translate(0%) translate(0px)!important;
            transform-origin: center;
            display: block;
            width: 120%;
            height: 120%;
            top: -5%;
            left: -5%;
            margin: 0;
            position: absolute;
            z-index: 8;
            filter: blur(20px);
        }
        img.poster{
            width: 105%;
            height: auto;
            display: block;
            border-radius: 4px;
            object-fit: cover;
            position: absolute;
            z-index: 9;
            top: 50%;
            transform: translateY(-50%);
            left: -2.5%;
            box-shadow: -6px 7px 10px 0px rgba(#444, 0.2);
        }
    }
}
</style>
